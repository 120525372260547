import React, { useEffect, useState } from "react";
import { config } from "../../../../../config";
import { useTranslation } from "react-i18next";
import { SzButton, SzIcon, SzTypographie, SzModal, SzAlert } from "@suezenv/react-theme-components";
import { MailType } from "../../../../../services/types/mail.type";
import MailRepository from "../../../../../services/api/repository/mail.repository";
import fileDownload from "js-file-download";
import { dateFormat } from "../../../../../services/utils/date.formatter";
import { AxiosError } from "axios";
import { SurveyRequest } from "../../../../../services/types/survey";

interface Props {
  mail: MailType;
  survey: SurveyRequest;
  setRefreshTable: any;
}

const MailItem: React.FC<Props> = ({ mail, survey, setRefreshTable }) => {
  const { t } = useTranslation(["common", "survey.view"]);
  
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    // display or hide delete button according the survey status
    if(!config.mailDeleteNotAllowedSurveyStatuses.includes(survey.currentStatus.status.id)) {
        setShowDeleteButton(true);
    }
  }, [survey.id]);

  const download = (format: string = '') => {
    MailRepository.download(mail, format).then((response) => {
      const extension = format === '' ? 'docx' : 'pdf';
      fileDownload(response.data, `${mail.filename.replace(/\.[^/.]+$/, "")}.${extension}`);
    });
  };

  const deleteMail = () => {
    MailRepository.deleteMail(mail)
    .then((response) => {
        setShowModal(false);
        // update state to refresh mail table
        setRefreshTable(Math.random());
    })       
    .catch((e: AxiosError<any>) => {
        setErrorMessage(t("survey.view:details.mail.modal.deleteError"));
        console.error(e.response);
    });
  };

  return (
    <>
        <SzModal title="" show={showModal} size="lg" handleClose={() => setShowModal(false)} centered>
            <p className="text-center">{t("survey.view:details.mail.modal.deleteConfirmation")}</p>
            {   
                errorMessage && 
                <SzAlert className="mb-4" variant="danger">
                    {errorMessage}
                </SzAlert>
            }
            <div className="mt-1 m-auto">
                <div className="row justify-content-around">
                    <SzButton variant="secondary" className="col-9 col-md-3 m-0" onClick={() => setShowModal(false)}>
                        {t("common:cancel")}
                    </SzButton>
                    <SzButton className="col-9 col-md-3 m-0 " onClick={() => deleteMail()}>
                        {t("common:confirm")}
                    </SzButton>
                </div>
            </div>
        </SzModal>

        <div className="row mb-4 mx-4 mt-4 justify-content-between border-bottom">
            <div className="col-3">
                <div className="row h-100">
                <div className="col-auto px-1 my-auto">
                    <SzIcon variant="line" icon="envelope" />
                </div>
                <div className="col-9 px-0">
                    <SzTypographie variant="caption" weight="light" className="long-text mb-0">
                    {t("survey.view:details.mail.mailName")}
                    </SzTypographie>
                    <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
                    {mail.templateName}
                    </SzTypographie>
                </div>
                </div>
            </div>
            <div className="col-2 m-0 p-0 has-border">
                <div className="row m-0 p-0 h-100">
                <div className="col-auto px-1 my-auto">
                    <SzIcon variant="line" icon="people-man-1" />
                </div>
                <div className="col-9 px-0">
                    <SzTypographie variant="caption" weight="light" className="long-text mb-0">
                    {t("survey.view:details.mail.generatedBy")}
                    </SzTypographie>
                    <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
                    {mail.owner.lastName} {mail.owner.firstName}
                    </SzTypographie>
                </div>
                </div>
            </div>
            <div className="col-2 m-0 p-0">
                <div className="row m-0 p-0 h-100">
                <div className="col-auto px-1 my-auto">
                    <SzIcon variant="line" icon="send-email-1" />
                </div>
                <div className="col-7 px-0">
                    <SzTypographie variant="caption" weight="light" className="long-text mb-0">
                    {t("survey.view:details.mail.recipient")}
                    </SzTypographie>
                    <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
                    {mail.contact.firstName} {mail.contact.name}
                    </SzTypographie>
                </div>
                </div>
            </div>
            <div className="col">
                <div className="row m-0 p-0 h-100">
                <div className="col-auto px-1 my-auto">
                    <SzIcon variant="line" icon="time-clock-hand-1" />
                </div>
                <div className="col px-0">
                    <SzTypographie variant="caption" weight="light" className="long-text mb-0">
                    {t("survey.view:details.mail.generatedAt")}
                    </SzTypographie>
                    <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
                    {dateFormat(mail.generationDate, "DD/MM/YY HH:mm")}
                    </SzTypographie>
                </div>
                </div>
            </div>
                {/* 
                {mail.comment && (
                    <div className="col">
                    <div className="row m-0 p-0 h-100">
                        <div className="col-auto px-1 my-auto">
                        <SzIcon variant="line" icon="messages-bubble-edit" />
                        </div>
                        <div className="col px-0">
                        <SzTypographie variant="caption" weight="light" className="long-text mb-0">
                            {t("details.mail.comment")}
                        </SzTypographie>
                        <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
                            {mail.comment}
                        </SzTypographie>
                        </div>
                    </div>
                    </div>
                )} */}
                <div className="col-auto p-0 mx-1 has-border d-flex align-items-center">
                    <i className="fa-custom-word fa-regular fa-file-word fa-xl ml-2 mr-3" onClick={() => download()} ></i>
                    {/* <i className="fa-custom-pdf fa-regular fa-file-pdf fa-xl" onClick={() => download('pdf')} ></i> */}
                    {
                        showDeleteButton &&
                        <i className="fa-custom-trash fa-regular fa-trash-can fa-xl" onClick={() => setShowModal(true)} ></i>
                    }
            </div>
         </div>
    </>
  );
};

export default MailItem;
